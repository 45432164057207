// import axios from 'axios'
import { PrivateApiCall, PublicApiCall } from "./interceptors";

const errorCatch = (error) => {
	if (error.response) {
		if (error.reponse?.data) {
			return error.response?.data;
		}
		return error.response;
	} else {
		return error;
	}
};

/** ******************************************************************************************* */
/** ************************************** AUTH *********************************************** */
/** ******************************************************************************************* */
async function login(data) {
	return await PublicApiCall.post("/super-admin/auth/login", data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getMyAccessPermissions() {
	return await PrivateApiCall.get("/super-admin/me/roles")
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getMe() {
	return await PrivateApiCall.get("/super-admin/me")
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function forgotPass(data) {
	return await PublicApiCall.post("/auth/forgotpass", data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}




/** ******************************************************************************************* */
/** ************************************** RESTAURANTS ******************************************* */
/** ******************************************************************************************* */
async function addRestaurant(data, customerId) {
	return await PrivateApiCall.post(`/super-admin/organisationrestaurant/${customerId}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function addRestBranch(data, customerId, restId) {
	return await PrivateApiCall.post(`/super-admin/organisationBranch/${customerId}/${restId}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getRestaurantsBranches(customerId) {
	return await PrivateApiCall.get(`/super-admin/organisationrestaurant/${customerId}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getOrgRestaurantsBranches(orgId) {
	return await PrivateApiCall.get(`/super-admin/license/restaurants/${orgId}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getOrgCustomers(orgId) {
	return await PrivateApiCall.get(`/super-admin/license/organisations/${orgId}/customers`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function editRestaurant(data, id) {
	return await PrivateApiCall.put(`/super-admin/organisationrestaurant/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function editRestBranch(data, branchId) {
	return await PrivateApiCall.put(`/super-admin/organisationBranch/${branchId}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}





/** ******************************************************************************************* */
/** ************************************** CURRENCIES ******************************************* */
/** ******************************************************************************************* */
async function addCurrency(data) {
	return await PrivateApiCall.post(`/super-admin/currencyRate/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllCurrencies() {
	return await PrivateApiCall.get(`/super-admin/currencyRate/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllCurrenciesList() {
	return await PrivateApiCall.get(`/super-admin/currencyRate/list/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getCurrency(id) {
	return await PrivateApiCall.get(`/super-admin/currencyRate/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updateCurrency(data, id) {
	return await PrivateApiCall.put(`/super-admin/currencyRate/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteCurrency(uuid) {
	return await PrivateApiCall.delete(`/super-admin/currencyRate/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


/** ******************************************************************************************* */
/** ************************************** PAYMENTS ******************************************* */
/** ******************************************************************************************* */
async function addPayment(data) {
	return await PrivateApiCall.post(`/super-admin/paymentType/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllPayments() {
	return await PrivateApiCall.get(`/super-admin/paymentType/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllPaymentsList() {
	return await PrivateApiCall.get(`/super-admin/paymentType/list/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getPayment(id) {
	return await PrivateApiCall.get(`/super-admin/paymentType/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updatePayment(data, id) {
	return await PrivateApiCall.put(`/super-admin/paymentType/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deletePayment(uuid) {
	return await PrivateApiCall.delete(`/super-admin/paymentType/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ************************************** RECEIPTS ******************************************* */
/** ******************************************************************************************* */
async function addReceipt(data) {
	return await PrivateApiCall.post(`/super-admin/receipt/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllReceipts(from, to) {
	return await PrivateApiCall.get(`/super-admin/receipt/${from}/${to}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getCustomerReceipts(id, from, to) {
	return await PrivateApiCall.get(`/super-admin/receipt/customerReceipts/${id}/${from}/${to}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getReceipt(id) {
	return await PrivateApiCall.get(`/super-admin/receipt/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updateReceipt(data, id) {
	return await PrivateApiCall.put(`/super-admin/receipt/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function deleteReceipt(uuid) {
	return await PrivateApiCall.delete(`/super-admin/receipt/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


/** ******************************************************************************************* */
/** ************************************** ORGANISATIONS ******************************************* */
/** ******************************************************************************************* */
async function addOrganisation(data, customerId) {
	return await PrivateApiCall.post(`/super-admin/customers/Organisations/${customerId}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function addOrganisationLicense(data) {
	return await PrivateApiCall.post(`/super-admin/license/organisations`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function editOrganisationLicense(data,uuid) {
	return await PrivateApiCall.put(`/super-admin/license/organisations/${uuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllOrganisations(customerId) {
	return await PrivateApiCall.get(`/super-admin/customers/Organisations/${customerId}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getOrganisations() {
	return await PrivateApiCall.get(`/super-admin/license/organisations/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getOrganisation(id) {
	return await PrivateApiCall.get(`/super-admin/license/organisations/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** CUSTOMERS ******************************************* */
/** ******************************************************************************************* */
async function getLinkedCustomers(uuid) {
	return await PrivateApiCall.get(`/super-admin/customers/${uuid}/linked`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addCustomer(data) {
	return await PrivateApiCall.post(`/super-admin/customers/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllCustomers(page, limit, search, isActive, sortBy, cities, states, customerGroups, controller) {
	let url = `/super-admin/customers?page=${page}&limit=${limit}`;

	if (search) {
		url += `&search=${search}`;
	}
	if (isActive != null) {
		url += `&isActive=${isActive}`;
	}
	if (sortBy) {
		url += `&sortBy=${sortBy}`;
	}
	if (cities && cities.length > 0) {
		cities.forEach(city => {
			url += `&cities=${city}`;
		});
	}
	if (states && states.length > 0) {
		states.forEach(state => {
			url += `&states=${state}`;
		});
	}
	if (customerGroups && customerGroups.length > 0) {
		customerGroups.forEach(group => {
			url += `&customerGroup=${group}`;
		});
	}

	return await PrivateApiCall.get(url, { controller })
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getCustomersWithNoOrg() {
	return await PrivateApiCall.get(`/super-admin/customers/no-organisation/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getCustomersCities() {
	return await PrivateApiCall.get(`/super-admin/customers/cities-states`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getCustomersStates() {
	return await PrivateApiCall.get(`/super-admin/customers/states`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getCallCustomers() {
	return await PrivateApiCall.get(`/super-admin/customers/sCustomers`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllCustomersList() {
	return await PrivateApiCall.get(`/super-admin/customers/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getCustomer(id) {
	return await PrivateApiCall.get(`/super-admin/customers/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


async function getCustomerGlobalConfig(id) {
	return await PrivateApiCall.get(`/super-admin/customers/getGlobalConfig/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updateCustomer(data, id) {
	return await PrivateApiCall.put(`/super-admin/customers/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteCustomer(uuid) {
	return await PrivateApiCall.delete(`/super-admin/customers/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addCustomerOutlet(data) {
	return await PrivateApiCall.post(`/super-admin/outlets/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllCustomerOutlets() {
	return await PrivateApiCall.get(`/super-admin/outlets/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllCustomerOutletsList() {
	return await PrivateApiCall.get(`/super-admin/outlets/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getCustomerOutlet(id) {
	return await PrivateApiCall.get(`/super-admin/outlets/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateCustomerOutlet(data, id) {
	return await PrivateApiCall.put(`/super-admin/outlets/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteCustomerOutlet(uuid) {
	return await PrivateApiCall.delete(`/super-admin/outlets/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addCustomerGroup(data) {
	return await PrivateApiCall.post(`/super-admin/customerGroups/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllCustomerGroups() {
	return await PrivateApiCall.get(`/super-admin/customerGroups/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllCustomerGroupsList() {
	return await PrivateApiCall.get(`/super-admin/customerGroups/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getCustomerGroup(id) {
	return await PrivateApiCall.get(`/super-admin/customerGroups/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateCustomerGroup(data, id) {
	return await PrivateApiCall.put(`/super-admin/customerGroups/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteCustomerGroup(uuid) {
	return await PrivateApiCall.delete(`/super-admin/customerGroups/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addCustomerPhone(data) {
	return await PrivateApiCall.post(`/super-admin/customerPhones/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function editCustomerPhone(data, uuid) {
	return await PrivateApiCall.put(`/super-admin/customerPhones/${uuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getCustomerPhones(id) {
	return await PrivateApiCall.get(`/super-admin/customerPhones/customer/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getCustomerPhone(id) {
	return await PrivateApiCall.get(`/super-admin/customerPhones/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteCustomerPhone(uuid) {
	return await PrivateApiCall.delete(`/super-admin/customerPhones/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getBusinessCards() {
	return await PrivateApiCall.get(`/super-admin/businessCard/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getBusinessCard(uuid) {
	return await PrivateApiCall.get(`/super-admin/businessCard/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addBusinessCard(data) {
	return await PrivateApiCall.post(`/super-admin/businessCard/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateBusinessCard(data, uuid) {
	return await PrivateApiCall.put(`/super-admin/businessCard/${uuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteBusinessCard(uuid) {
	return await PrivateApiCall.delete(`/super-admin/businessCard/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


/** ******************************************************************************************* */
/** ************************************** PRODUCTS ******************************************* */
/** ******************************************************************************************* */
async function addProduct(data) {
	return await PrivateApiCall.post(`/super-admin/products/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProducts() {
	return await PrivateApiCall.get(`/super-admin/products/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProductsList() {
	return await PrivateApiCall.get(`/super-admin/products/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


async function getProduct(id) {
	return await PrivateApiCall.get(`/super-admin/products/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateProduct(data, id) {
	return await PrivateApiCall.put(`/super-admin/products/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteProduct(uuid) {
	return await PrivateApiCall.delete(`/super-admin/products/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addProductBrand(data) {
	return await PrivateApiCall.post(`/super-admin/productBrands/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProductBrands() {
	return await PrivateApiCall.get(`/super-admin/productBrands/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllProductBrandsList() {
	return await PrivateApiCall.get(`/super-admin/productBrands/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getProductBrand(id) {
	return await PrivateApiCall.get(`/super-admin/productBrands/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateProductBrand(data, id) {
	return await PrivateApiCall.put(`/super-admin/productBrands/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteProductBrand(uuid) {
	return await PrivateApiCall.delete(`/super-admin/productBrands/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


async function addProductType(data) {
	return await PrivateApiCall.post(`/super-admin/productTypes/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProductTypes() {
	return await PrivateApiCall.get(`/super-admin/productTypes/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProductTypesList() {
	return await PrivateApiCall.get(`/super-admin/productTypes/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getProductType(id) {
	return await PrivateApiCall.get(`/super-admin/productTypes/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateProductType(data, id) {
	return await PrivateApiCall.put(`/super-admin/productTypes/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteProductType(uuid) {
	return await PrivateApiCall.delete(`/super-admin/productTypes/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


async function addProductGroup(data) {
	return await PrivateApiCall.post(`/super-admin/productGroups/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProductGroups() {
	return await PrivateApiCall.get(`/super-admin/productGroups/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProductGroupsList() {
	return await PrivateApiCall.get(`/super-admin/productGroups/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getProductGroup(id) {
	return await PrivateApiCall.get(`/super-admin/productGroups/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateProductGroup(data, id) {
	return await PrivateApiCall.put(`/super-admin/productGroups/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteProductGroup(uuid) {
	return await PrivateApiCall.delete(`/super-admin/productGroups/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addProductCategory(data) {
	return await PrivateApiCall.post(`/super-admin/productCategories/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProductCategories() {
	return await PrivateApiCall.get(`/super-admin/productCategories/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProductCategoriesList() {
	return await PrivateApiCall.get(`/super-admin/productCategories/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getProductCategory(id) {
	return await PrivateApiCall.get(`/super-admin/productCategories/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateProductCategory(data, id) {
	return await PrivateApiCall.put(`/super-admin/productCategories/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteProductCategory(uuid) {
	return await PrivateApiCall.delete(`/super-admin/productCategories/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


async function addProductUnit(data) {
	return await PrivateApiCall.post(`/super-admin/productUnits/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProductUnits() {
	return await PrivateApiCall.get(`/super-admin/productUnits/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllProductUnitsList() {
	return await PrivateApiCall.get(`/super-admin/productUnits/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getProductUnit(id) {
	return await PrivateApiCall.get(`/super-admin/productUnits/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateProductUnit(data, id) {
	return await PrivateApiCall.put(`/super-admin/productUnits/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteProductUnit(uuid) {
	return await PrivateApiCall.delete(`/super-admin/productUnits/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function sortProducts(data, id) {
	return await PrivateApiCall.put(`/super-admin/menu-display/products/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function sortGroups(data) {
	return await PrivateApiCall.put(`/super-admin/menu-display/groups/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getGroupProducts() {
	return await PrivateApiCall.get(`/super-admin/products/group/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** SUPPLIERS ******************************************* */
/** ******************************************************************************************* */
async function addSupplier(data) {
	return await PrivateApiCall.post(`/super-admin/suppliers/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSuppliers() {
	return await PrivateApiCall.get(`/super-admin/suppliers/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSuppliersList() {
	return await PrivateApiCall.get(`/super-admin/suppliers/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getSupplier(id) {
	return await PrivateApiCall.get(`/super-admin/suppliers/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateSupplier(data, id) {
	return await PrivateApiCall.put(`/super-admin/suppliers/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteSupplier(uuid) {
	return await PrivateApiCall.delete(`/super-admin/suppliers/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** TAXES ******************************************* */
/** ******************************************************************************************* */
async function addTax(data) {
	return await PrivateApiCall.post(`/super-admin/tax/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllTaxes() {
	return await PrivateApiCall.get(`/super-admin/tax/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllTaxesList() {
	return await PrivateApiCall.get(`/super-admin/tax/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getTax(id) {
	return await PrivateApiCall.get(`/super-admin/tax/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateTax(data, id) {
	return await PrivateApiCall.put(`/super-admin/tax/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteTax(uuid) {
	return await PrivateApiCall.delete(`/super-admin/tax/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** SALES ******************************************* */
/** ******************************************************************************************* */
async function addSale(data) {
	return await PrivateApiCall.post(`/super-admin/sales/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSales() {
	return await PrivateApiCall.get(`/super-admin/sales/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSalesList() {
	return await PrivateApiCall.get(`/super-admin/sales/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateSale(data, id) {
	return await PrivateApiCall.put(`/super-admin/sales/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteSale(uuid) {
	return await PrivateApiCall.delete(`/super-admin/sales/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** LEADS ******************************************* */
/** ******************************************************************************************* */
async function getLinkedLeads(uuid) {
	return await PrivateApiCall.get(`/super-admin/leads/${uuid}/linked`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addLeadSource(data) {
	return await PrivateApiCall.post(`/super-admin/leadSource/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllLeadSource() {
	return await PrivateApiCall.get(`/super-admin/leadSource/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllLeadSourceList() {
	return await PrivateApiCall.get(`/super-admin/leadSource/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getLeadSource(id) {
	return await PrivateApiCall.get(`/super-admin/leadSource/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateLeadSource(data, id) {
	return await PrivateApiCall.put(`/super-admin/leadSource/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteLeadSource(uuid) {
	return await PrivateApiCall.delete(`/super-admin/leadSource/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addQuotationStatus(data) {
	return await PrivateApiCall.post(`/super-admin/quotationStatus/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllQuotationStatus() {
	return await PrivateApiCall.get(`/super-admin/quotationStatus/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllQuotationStatusList() {
	return await PrivateApiCall.get(`/super-admin/quotationStatus/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getQuotationUsers() {
	return await PrivateApiCall.get("/super-admin/quotation/users")
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getQuotationStatus(id) {
	return await PrivateApiCall.get(`/super-admin/quotationStatus/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateQuotationStatus(data, id) {
	return await PrivateApiCall.put(`/super-admin/quotationStatus/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteQuotationStatus(uuid) {
	return await PrivateApiCall.delete(`/super-admin/quotationStatus/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}



async function addQuotationLostReason(data) {
	return await PrivateApiCall.post(`/super-admin/quotationLostReason/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllQuotationLostReason() {
	return await PrivateApiCall.get(`/super-admin/quotationLostReason/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllQuotationLostReasonList() {
	return await PrivateApiCall.get(`/super-admin/quotationLostReason/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getQuotationLostReason(id) {
	return await PrivateApiCall.get(`/super-admin/quotationLostReason/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateQuotationLostReason(data, id) {
	return await PrivateApiCall.put(`/super-admin/quotationLostReason/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteQuotationLostReason(uuid) {
	return await PrivateApiCall.delete(`/super-admin/quotationLostReason/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addLeadProcess(data) {
	return await PrivateApiCall.post(`/super-admin/leadProcess/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllLeadProcess() {
	return await PrivateApiCall.get(`/super-admin/leadProcess/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllLeadProcessList() {
	return await PrivateApiCall.get(`/super-admin/leadProcess/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getLeadProcess(id) {
	return await PrivateApiCall.get(`/super-admin/leadProcess/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateLeadProcess(data, id) {
	return await PrivateApiCall.put(`/super-admin/leadProcess/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteLeadProcess(uuid) {
	return await PrivateApiCall.delete(`/super-admin/leadProcess/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addSaleRegion(data) {
	return await PrivateApiCall.post(`/super-admin/saleRegions/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSaleRegions() {
	return await PrivateApiCall.get(`/super-admin/saleRegions/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSaleRegionsList() {
	return await PrivateApiCall.get(`/super-admin/saleRegions/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getSaleRegion(id) {
	return await PrivateApiCall.get(`/super-admin/saleRegions/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateSaleRegion(data, id) {
	return await PrivateApiCall.put(`/super-admin/saleRegions/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteSaleRegion(uuid) {
	return await PrivateApiCall.delete(`/super-admin/saleRegions/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addLead(data) {
	return await PrivateApiCall.post(`/super-admin/leads/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getLeadQuotations(uuid) {
	return await PrivateApiCall.get(`/super-admin/quotation/lead/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


async function getAllLeads() {
	return await PrivateApiCall.get(`/super-admin/leads/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllUnlinkedLeads() {
	return await PrivateApiCall.get(`/super-admin/leads/unlinked`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getSalesLeads() {
	return await PrivateApiCall.get(`/super-admin/leads/sLeads/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllLeadsList() {
	return await PrivateApiCall.get(`/super-admin/leads/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getLead(id) {
	return await PrivateApiCall.get(`/super-admin/leads/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateLead(data, id) {
	return await PrivateApiCall.put(`/super-admin/leads/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteLead(uuid) {
	return await PrivateApiCall.delete(`/super-admin/leads/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllStatementsBranch(id, from, to, branchUuid) {
	return await PrivateApiCall.get(`/super-admin/receipt/customer/${id}/${from}/${to}/${branchUuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllStatements(id, from, to) {
	return await PrivateApiCall.get(`/super-admin/receipt/customer/${id}/${from}/${to}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** USERS ******************************************* */
/** ******************************************************************************************* */
async function addUser(data) {
	return await PrivateApiCall.post(`/super-admin/users/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllUsers() {
	return await PrivateApiCall.get(`/super-admin/users/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllUsersList() {
	return await PrivateApiCall.get(`/super-admin/users/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getUser(id) {
	return await PrivateApiCall.get(`/super-admin/users/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateUser(data, id) {
	return await PrivateApiCall.put(`/super-admin/users/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteUser(uuid) {
	return await PrivateApiCall.delete(`/super-admin/users/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addUserRole(data) {
	return await PrivateApiCall.post(`/super-admin/roles/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllUserRoles() {
	return await PrivateApiCall.get(`/super-admin/roles/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllUserRolesList() {
	return await PrivateApiCall.get(`/super-admin/roles/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getUserRole(id) {
	return await PrivateApiCall.get(`/super-admin/roles/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateUserRole(data, id) {
	return await PrivateApiCall.put(`/super-admin/roles/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteUserRole(uuid) {
	return await PrivateApiCall.delete(`/super-admin/roles/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** COMPANIES ******************************************* */
/** ******************************************************************************************* */
async function addCompany(data) {
	return await PrivateApiCall.post(`/super-admin/company/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllCompanies() {
	return await PrivateApiCall.get(`/super-admin/company/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllCompaniesList() {
	return await PrivateApiCall.get(`/super-admin/company/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getCompany(id) {
	return await PrivateApiCall.get(`/super-admin/company/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateCompany(data, id) {
	return await PrivateApiCall.put(`/super-admin/company/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteCompany(uuid) {
	return await PrivateApiCall.delete(`/super-admin/company/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addOutlet(data) {
	return await PrivateApiCall.post(`/super-admin/branch/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllOutlets() {
	return await PrivateApiCall.get(`/super-admin/branch/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllOutletsList() {
	return await PrivateApiCall.get(`/super-admin/branch/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getOutlet(id) {
	return await PrivateApiCall.get(`/super-admin/branch/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateOutlet(data, id) {
	return await PrivateApiCall.put(`/super-admin/branch/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteOutlet(uuid) {
	return await PrivateApiCall.delete(`/super-admin/branch/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** LANGUAGES ******************************************* */
/** ******************************************************************************************* */
async function addLanguage(data) {
	return await PrivateApiCall.post(`/super-admin/translation/language/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getLanguage(id) {
	return await PrivateApiCall.get(`/super-admin/translation/language/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllLanguages() {
	return await PrivateApiCall.get(`/super-admin/translation/language`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllLanguagesList() {
	return await PrivateApiCall.get(`/super-admin/translation/language/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updateLanguage(data, id) {
	return await PrivateApiCall.put(`/super-admin/translation/language/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteLanguage(uuid) {
	return await PrivateApiCall.delete(`/super-admin/translation/language/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** WAREHOUSES ******************************************* */
/** ******************************************************************************************* */
async function addWarehouse(data) {
	return await PrivateApiCall.post(`/super-admin/warehouse/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllWarehouses() {
	return await PrivateApiCall.get(`/super-admin/warehouse/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getWarehouse(id) {
	return await PrivateApiCall.get(`/super-admin/warehouse/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateWarehouse(data, id) {
	return await PrivateApiCall.put(`/super-admin/warehouse/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteWarehouse(uuid) {
	return await PrivateApiCall.delete(`/super-admin/warehouse/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** TRANSLATIONS ******************************************* */
/** ******************************************************************************************* */
async function generateTranslations(data){
	return await PrivateApiCall.post("/super-admin/translation/generate-translation",data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getTranslations() {
	return await PrivateApiCall.get("/language")
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function uploadTranslations(data) {
	return await PrivateApiCall.post("/super-admin/translation", data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updateTranslations(data) {
	return await PrivateApiCall.post("/super-admin/translation/update", data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function downloadTranslations() {
	return await PrivateApiCall.get("/super-admin/translation")
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** GLOBAL CONFIG ************************************** */
/** ******************************************************************************************* */

async function getGlobalConfig() {
	return await PublicApiCall.get("/super-admin/globalConfig")
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** SERVICE CALLS ******************************************* */
/** ******************************************************************************************* */
async function addServiceCall(data) {
	const branchUuid = data.branchUuid;
	delete data.branchUuid;
	return await PrivateApiCall.post(`/super-admin/serviceCall/${branchUuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllServiceCalls() {
	return await PrivateApiCall.get(`/super-admin/serviceCall/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getServiceCallDelivery(uuid) {
	return await PrivateApiCall.get(`/super-admin/serviceCall/${uuid}/delivery`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getCustomerServiceCalls(uuid) {
	return await PrivateApiCall.get(`/super-admin/serviceCall/customer/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getPaginatedCalls(page, limit, status, filters = {}) {

	let url = `/super-admin/serviceCall/filtered/${status}?page=${page}&limit=${limit}&sortBy=dueDate`;
	let keys = Object.keys(filters);
	keys.forEach((key) => {
		const value = filters[key];

		if (Array.isArray(value) && value.length > 0) {
			value.forEach((filter) => {
				url += `&${key}=${filter}`;
			});
		} else if (typeof value === 'object' && value !== null) {
			for (const objKey in value) {
				if (value.hasOwnProperty(objKey)) {
					url += `&${key}[${objKey}]=${value[objKey]}`;
				}
			}
		} else if (typeof value === 'string' && value.trim() !== '') {
			url += `&${key}=${value}`;
		} else if (typeof value === 'number' && !isNaN(value)) {
			url += `&${key}=${value}`;
		}
	})
	return await PrivateApiCall.get(url)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getPaginatedSalesCalls(page, limit, status, filters = {}) {

	let url = `/super-admin/salesCall/filtered/${status}?page=${page}&limit=${limit}&sortBy=dueDate`;
	let keys = Object.keys(filters);
	keys.forEach((key) => {
		const value = filters[key];

		if (Array.isArray(value) && value.length > 0) {
			value.forEach((filter) => {
				url += `&${key}=${filter}`;
			});
		} else if (typeof value === 'object' && value !== null) {
			for (const objKey in value) {
				if (value.hasOwnProperty(objKey)) {
					url += `&${key}[${objKey}]=${value[objKey]}`;
				}
			}
		} else if (typeof value === 'string' && value.trim() !== '') {
			url += `&${key}=${value}`;
		} else if (typeof value === 'number' && !isNaN(value)) {
			url += `&${key}=${value}`;
		}
	})
	return await PrivateApiCall.get(url)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllSalesCallsDashboard(filter) {
	return await PrivateApiCall.get(`/super-admin/salesCall/filtered/${filter}/dashboard?page=1&limit=99999`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllServiceCallsDashboard(filter) {
	return await PrivateApiCall.get(`/super-admin/serviceCall/filtered/${filter}/dashboard?page=1&limit=99999`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getServiceCall(id) {
	return await PrivateApiCall.get(`/super-admin/serviceCall/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateServiceCall(data, id) {
	const branchUuid = data.branchUuid;
	delete data.branchUuid;
	return await PrivateApiCall.put(`/super-admin/serviceCall/${branchUuid}/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateDeliveryDetails(data, id) {
	return await PrivateApiCall.put(`/super-admin/serviceCall/${id}/delivery`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function cancelDelivery(data, id) {
	return await PrivateApiCall.put(`/super-admin/serviceCall/${id}/cancelDelivery`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateComments(data, id) {
	return await PrivateApiCall.put(`/super-admin/serviceCallComment/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteComment(id) {
	return await PrivateApiCall.delete(`/super-admin/serviceCallComment/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addComments(data) {
	return await PrivateApiCall.post(`/super-admin/serviceCallComment`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteServiceCall(uuid, status) {
	return await PrivateApiCall.delete(`/super-admin/serviceCall/${uuid}/${status}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addServiceCallType(data) {
	return await PrivateApiCall.post(`/super-admin/serviceCallType/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllServiceCallType() {
	return await PrivateApiCall.get(`/super-admin/serviceCallType/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllServiceCallTypeList() {
	return await PrivateApiCall.get(`/super-admin/serviceCallType/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllServiceCallTypeCount(from, to, status) {
	return await PrivateApiCall.get(`/super-admin/serviceCallType/sCount/${from}/${to}/${status}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getServiceCallType(id) {
	return await PrivateApiCall.get(`/super-admin/serviceCallType/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updateServiceCallType(data, id) {
	return await PrivateApiCall.put(`/super-admin/serviceCallType/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteServiceCallType(uuid) {
	return await PrivateApiCall.delete(`/super-admin/serviceCallType/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addServiceCallStatus(data) {
	return await PrivateApiCall.post(`/super-admin/serviceCallStatus/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllServiceCallStatus() {
	return await PrivateApiCall.get(`/super-admin/serviceCallStatus/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllServiceCallStatusList() {
	return await PrivateApiCall.get(`/super-admin/serviceCallStatus/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getServiceCallStatus(id) {
	return await PrivateApiCall.get(`/super-admin/serviceCallStatus/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateServiceCallStatus(data, id) {
	return await PrivateApiCall.put(`/super-admin/serviceCallStatus/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteServiceCallStatus(uuid) {
	return await PrivateApiCall.delete(`/super-admin/serviceCallStatus/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}




async function addServiceCallCategory(data) {
	return await PrivateApiCall.post(`/super-admin/serviceCallDepartment/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllServiceCallCategory() {
	return await PrivateApiCall.get(`/super-admin/serviceCallDepartment/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getComments(id) {
	return await PrivateApiCall.get(`/super-admin/serviceCallComment/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getServiceCallCategory(id) {
	return await PrivateApiCall.get(`/super-admin/serviceCallDepartment/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updateServiceCallCategory(data, id) {
	return await PrivateApiCall.put(`/super-admin/serviceCallDepartment/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteServiceCallCategory(uuid) {
	return await PrivateApiCall.delete(`/super-admin/serviceCallDepartment/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllServiceCallCategoryList() {
	return await PrivateApiCall.get(`/super-admin/serviceCallDepartment/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


async function getRequestsCount() {
	return await PrivateApiCall.get('/super-admin/demo/count')
	.then((response) => response)
	.catch((error) => errorCatch(error)); 
}

async function getRequests(filters) {
	let url = `/super-admin/demo?`;
	let keys = Object.keys(filters);
	let isFirstParam = true;

	// Loop through each key in the filters object
	keys.forEach((key) => {
		const value = filters[key];

		// Handle array values
		if (Array.isArray(value) && value.length > 0) {
			const queryString = value
				.map((filter) => `${key}=${encodeURIComponent(filter)}`)
				.join('&');

			// Append the query string, handling the first parameter correctly
			url += isFirstParam ? queryString : `&${queryString}`;
			isFirstParam = false;

			// Handle object values
		} else if (typeof value === 'object' && value !== null) {
			Object.keys(value).forEach((objKey, index) => {
				const prefix = isFirstParam && index === 0 ? '' : '&';
				url += `${prefix}${key}[${objKey}]=${encodeURIComponent(value[objKey])}`;
				isFirstParam = false;
			});

			// Handle string values
		} else if (typeof value === 'string' && value.trim() !== '') {
			url += isFirstParam ? `${key}=${encodeURIComponent(value)}` : `&${key}=${encodeURIComponent(value)}`;
			isFirstParam = false;

			// Handle numeric values
		} else if (typeof value === 'number' && !isNaN(value)) {
			url += isFirstParam ? `${key}=${value}` : `&${key}=${value}`;
			isFirstParam = false;
		}
	});

	return await PrivateApiCall.get(url)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updateRequest(data, id, filters) {
	let url = `/super-admin/demo/${id}?`;
	let keys = Object.keys(filters);
	keys.forEach((key) => {
		const value = filters[key];

		if (Array.isArray(value) && value.length > 0) {
			value.forEach((filter, i) => {

				url += `${i > 0 ? '&' : ''}${key}=${filter}`;
			});
		} else if (typeof value === 'object' && value !== null) {
			for (const objKey in value) {
				if (value.hasOwnProperty(objKey)) {
					url += `&${key}[${objKey}]=${value[objKey]}`;
				}
			}
		} else if (typeof value === 'string' && value.trim() !== '') {
			url += `&${key}=${value}`;
		} else if (typeof value === 'number' && !isNaN(value)) {
			url += `&${key}=${value}`;
		}
	})
	return await PrivateApiCall.put(url, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


async function addEmailServer(data) {
	return await PrivateApiCall.post(`/super-admin/organisationEmailServer`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getEmailServer() {
	return await PrivateApiCall.get(`/super-admin/organisationEmailServer?crmModule=demo-request`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


async function addEmailDestinations(data) {
	return await PrivateApiCall.post(`/super-admin/emails-destination/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getEmailDestinations() {
	return await PrivateApiCall.get(`/super-admin/emails-destination/demo-request`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** ZONES ******************************************* */
/** ******************************************************************************************* */
async function addZone(data) {
	return await PrivateApiCall.post(`/super-admin/zones/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllZones() {
	return await PrivateApiCall.get(`/super-admin/zones/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getZone(id) {
	return await PrivateApiCall.get(`/super-admin/zones/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateZone(data, id) {
	return await PrivateApiCall.put(`/super-admin/zones/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteZone(uuid) {
	return await PrivateApiCall.delete(`/super-admin/zones/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************** Merchant Schema ************************************************ */
/** ******************************************************************************************* */
async function getAllCustomerSchema(data) {
	return await PrivateApiCall.get(`/super-admin/customerSchema/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getCustomerSchema(id) {
	return await PrivateApiCall.get(`/super-admin/customerSchema/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function editCustomerSchema(data, id) {
	return await PrivateApiCall.put(`/super-admin/customerSchema/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function editCustomerSchemaProducts(data, id) {
	return await PrivateApiCall.put(`/super-admin/customerSchema/product/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function addCustomerSchemaProducts(data, id) {
	return await PrivateApiCall.post(`/super-admin/customerSchema/products/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function deleteCustomerSchemaProducts(uuid) {
	return await PrivateApiCall.delete(`/super-admin/customerSchema/product/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}




/** ******************************************************************************************* */
/** ************************** Upload Customer Logo ** **************************************** */
/** ******************************************************************************************* */
async function uploadCustLogo(data) {
	return await PrivateApiCall.post(`/super-admin/upload/custLogo`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************** Upload Image ** **************************************** */
/** ******************************************************************************************* */
async function uploadImage(data) {
	return await PrivateApiCall.post(`/super-admin/upload/image`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function uploadProductImage(data, id) {
	return await PrivateApiCall.post(`/super-admin/upload/product-image/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** INVOICE *********************************************** */
/** ******************************************************************************************* */

async function addInvoice(data) {
	const branchUuid = data.branchUuid;
	delete data.branchUuid;

	return await PrivateApiCall.post(`/super-admin/invoice/${branchUuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function editInvoice(data, uuid) {
	const branchUuid = data.branchUuid;
	delete data.branchUuid;

	return await PrivateApiCall.put(`/super-admin/invoice/${branchUuid}/${uuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function deleteInvoice(uuid) {

	return await PrivateApiCall.delete(`/super-admin/invoice/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllInvoices(page, limit, filters) {
	let url = `/super-admin/invoice?page=${page}&limit=${limit}`;
	let keys = Object.keys(filters);

	keys.forEach((key) => {
		const value = filters[key];

		if (Array.isArray(value) && value.length > 0) {
			value.forEach((filter) => {
				url += `&${key}=${filter}`;
			});
		} else if (typeof value === 'object' && value !== null) {
			for (const objKey in value) {
				if (value.hasOwnProperty(objKey)) {
					url += `&${key}[${objKey}]=${value[objKey]}`;
				}
			}
		} else if (typeof value === 'string' && value.trim() !== '') {
			url += `&${key}=${value}`;
		} else if (typeof value === 'number' && !isNaN(value)) {
			url += `&${key}=${value}`;
		}

	});

	return await PrivateApiCall.get(url)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllInvoicesFilter(filter) {
	return await PrivateApiCall.get(`/super-admin/invoice/filter/${filter}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getInvoice(id) {
	return await PrivateApiCall.get(`/super-admin/invoice/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getInvoiceUsers() {
	return await PrivateApiCall.get("/super-admin/invoice/users")
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getInvoiceParameters() {
	return await PrivateApiCall.get(`/super-admin/invoiceParameters/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getInvoiceParametersList() {
	return await PrivateApiCall.get(`/super-admin/invoiceParameters/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function editInvoiceParameters(data) {
	return await PrivateApiCall.put(`/super-admin/invoiceParameters/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


/** ******************************************************************************************* */
/** ************************************** QUOTATION *********************************************** */
/** ******************************************************************************************* */

async function addQuotation(data) {
	const branchUuid = data.branchUuid;
	delete data.branchUuid;
	return await PrivateApiCall.post(`/super-admin/quotation/${branchUuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function editQuotation(data, uuid) {
	const branchUuid = data.branchUuid;
	delete data.branchUuid;

	return await PrivateApiCall.put(`/super-admin/quotation/${branchUuid}/${uuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function deleteQuotation(uuid, status) {

	return await PrivateApiCall.delete(`/super-admin/quotation/${uuid}/${status}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllQuotations(page, limit, status, filters) {

	let url = `/super-admin/quotation/filter/${status}?page=${page}&limit=${limit}`;
	let keys = Object.keys(filters);

	keys.forEach((key) => {
		const value = filters[key];

		if (Array.isArray(value) && value.length > 0) {
			value.forEach((filter) => {
				url += `&${key}=${filter}`;
			});
		} else if (typeof value === 'object' && value !== null) {
			for (const objKey in value) {
				if (value.hasOwnProperty(objKey)) {
					url += `&${key}[${objKey}]=${value[objKey]}`;
				}
			}
		} else if (typeof value === 'string' && value.trim() !== '') {
			url += `&${key}=${value}`;
		} else if (typeof value === 'number' && !isNaN(value)) {
			url += `&${key}=${value}`;
		}

	});


	return await PrivateApiCall.get(url)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllQuotationsFilter(filter) {
	return await PrivateApiCall.get(`/super-admin/quotation/filter/${filter}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getQuotation(id) {
	return await PrivateApiCall.get(`/super-admin/quotation/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getParameters() {
	return await PrivateApiCall.get(`/super-admin/quotationParameters/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getParametersList() {
	return await PrivateApiCall.get(`/super-admin/quotationParameters/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function editParameters(data) {
	return await PrivateApiCall.put(`/super-admin/quotationParameters/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

//GET ALL PAGES
async function getAllPages() {
	return await PrivateApiCall.get(`/super-admin/adminPage/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

//GET PAGE MODULES
async function getPageModules() {
	return await PrivateApiCall.get(`/super-admin/adminPage/modules`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function editPage(data, id) {
	return await PrivateApiCall.put(`/super-admin/adminPage/${id}/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function editPages(data) {
	return await PrivateApiCall.put(`/super-admin/adminPage/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function editModulePage(data, id) {
	return await PrivateApiCall.put(`/super-admin/adminPage/modules/${id}/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function addPage(data) {
	return await PrivateApiCall.post(`/super-admin/adminPage/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function deletePage(uuid) {
	return await PrivateApiCall.delete(`/super-admin/adminPage/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getRoutes() {
	return await PrivateApiCall.get("/super-admin/admin-roles/getRoutes")
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function editRoutes(data, uuid) {
	return await PrivateApiCall.put(`/super-admin/admin-roles/assignRoutes/${uuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getSubAccess() {
	return await PrivateApiCall.get("/super-admin/admin-roles/getSubAccesses")
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function editSubAccess(data, uuid) {
	return await PrivateApiCall.put(`/super-admin/admin-roles/editSubAccess/${uuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}



/** ******************************************************************************************* */
/** ************************************** SALES TASKS*********************************************** */
/** ******************************************************************************************* */

async function addSalesCall(data) {
	const branchUuid = data.branchUuid;
	delete data.branchUuid;
	return await PrivateApiCall.post(`/super-admin/salesCall/${branchUuid}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSalesCalls() {
	return await PrivateApiCall.get(`/super-admin/salesCall/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllSalesCallsFilter(filter) {
	return await PrivateApiCall.get(`/super-admin/salesCall/filtered/${filter}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getSalesCall(id) {
	return await PrivateApiCall.get(`/super-admin/salesCall/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateSalesCall(data, id) {
	const branchUuid = data.branchUuid;
	delete data.branchUuid;
	return await PrivateApiCall.put(`/super-admin/salesCall/${branchUuid}/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateSalesComments(data, id) {
	return await PrivateApiCall.put(`/super-admin/salesCallComment/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteSalesComment(id) {
	return await PrivateApiCall.delete(`/super-admin/salesCallComment/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addSalesComments(data) {
	return await PrivateApiCall.post(`/super-admin/salesCallComment`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function deleteSalesCall(uuid, status) {
	return await PrivateApiCall.delete(`/super-admin/salesCall/${uuid}/${status}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addSalesCallType(data) {
	return await PrivateApiCall.post(`/super-admin/salesCallType/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSalesCallType() {
	return await PrivateApiCall.get(`/super-admin/salesCallType/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSalesCallTypeList() {
	return await PrivateApiCall.get(`/super-admin/salesCallType/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getAllSalesCallTypeCount(from, to, status) {
	return await PrivateApiCall.get(`/super-admin/salesCallType/sCount/${from}/${to}/${status}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getSalesCallType(id) {
	return await PrivateApiCall.get(`/super-admin/salesCallType/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updateSalesCallType(data, id) {
	return await PrivateApiCall.put(`/super-admin/salesCallType/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteSalesCallType(uuid) {
	return await PrivateApiCall.delete(`/super-admin/salesCallType/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function addSalesCallStatus(data) {
	return await PrivateApiCall.post(`/super-admin/salesCallStatus/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSalesCallStatus() {
	return await PrivateApiCall.get(`/super-admin/salesCallStatus/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSalesCallStatusList() {
	return await PrivateApiCall.get(`/super-admin/salesCallStatus/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getSalesCallStatus(id) {
	return await PrivateApiCall.get(`/super-admin/salesCallStatus/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateSalesCallStatus(data, id) {
	return await PrivateApiCall.put(`/super-admin/salesCallStatus/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteSalesCallStatus(uuid) {
	return await PrivateApiCall.delete(`/super-admin/salesCallStatus/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}




async function addSalesCallCategory(data) {
	return await PrivateApiCall.post(`/super-admin/salesCallDepartment/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSalesCallCategory() {
	return await PrivateApiCall.get(`/super-admin/salesCallDepartment/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function getSalesComments(id) {
	return await PrivateApiCall.get(`/super-admin/salesCallComment/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getSalesCallCategory(id) {
	return await PrivateApiCall.get(`/super-admin/salesCallDepartment/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}
async function updateSalesCallCategory(data, id) {
	return await PrivateApiCall.put(`/super-admin/salesCallDepartment/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteSalesCallCategory(uuid) {
	return await PrivateApiCall.delete(`/super-admin/salesCallDepartment/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllSalesCallCategoryList() {
	return await PrivateApiCall.get(`/super-admin/salesCallDepartment/list`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}



async function addAutomation(data) {
	return await PrivateApiCall.post(`/super-admin/automation/`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAllAutomations() {
	return await PrivateApiCall.get(`/super-admin/automation/`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function getAutomation(id) {
	return await PrivateApiCall.get(`/super-admin/automation/${id}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function updateAutomation(data, id) {
	return await PrivateApiCall.put(`/super-admin/automation/${id}`, data)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

async function deleteAutomation(uuid) {
	return await PrivateApiCall.delete(`/super-admin/automation/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** ACTIVITIES *********************************************** */
/** ******************************************************************************************* */

async function getActivities(uuid) {
	return await PublicApiCall.get(`/super-admin/activity/${uuid}`)
		.then((response) => response)
		.catch((error) => errorCatch(error));
}


export {
	// AUTH
	login,
	getMyAccessPermissions,
	forgotPass,
	getMe,
	//CUSTOMERS
	addCustomer,
	getAllCustomers,
	getAllCustomersList,
	getLinkedCustomers,
	getCustomersWithNoOrg,
	getCustomersCities,
	getCustomersStates,
	getCallCustomers,
	getCustomer,
	updateCustomer,
	deleteCustomer,
	getCustomerGlobalConfig,
	addCustomerOutlet,
	getAllCustomerOutlets,
	getAllCustomerOutletsList,
	getCustomerOutlet,
	updateCustomerOutlet,
	deleteCustomerOutlet,
	addCustomerGroup,
	getAllCustomerGroups,
	getAllCustomerGroupsList,
	getCustomerGroup,
	updateCustomerGroup,
	deleteCustomerGroup,
	getCustomerPhones,
	getCustomerPhone,
	addCustomerPhone,
	editCustomerPhone,
	deleteCustomerPhone,
	getBusinessCards,
	getBusinessCard,
	addBusinessCard,
	updateBusinessCard,
	deleteBusinessCard,
	// PRODUCTS
	getGroupProducts,
	sortGroups,
	sortProducts,
	addProduct,
	getAllProducts,
	getAllProductsList,
	getAllProductUnitsList,
	getProduct,
	updateProduct,
	deleteProduct,
	addProductType,
	getAllProductTypes,
	getAllProductTypesList,
	getProductType,
	updateProductType,
	deleteProductType,
	addProductGroup,
	getAllProductGroups,
	getAllProductGroupsList,
	getProductGroup,
	updateProductGroup,
	deleteProductGroup,
	addProductCategory,
	getAllProductCategories,
	getAllProductCategoriesList,
	getProductCategory,
	updateProductCategory,
	deleteProductCategory,
	addProductBrand,
	getAllProductBrands,
	getAllProductBrandsList,
	updateProductBrand,
	deleteProductBrand,
	getProductBrand,
	addProductUnit,
	getAllProductUnits,
	updateProductUnit,
	deleteProductUnit,
	getProductUnit,

	//SUPPLIERS
	addSupplier,
	getAllSuppliers,
	getAllSuppliersList,
	getSupplier,
	updateSupplier,
	deleteSupplier,
	//TAXES
	addTax,
	getAllTaxes,
	getAllTaxesList,
	getTax,
	updateTax,
	deleteTax,
	// SALES
	addSale,
	getAllSales,
	getAllSalesList,
	updateSale,
	deleteSale,
	//LEADS
	getLinkedLeads,
	getSalesLeads,
	addLeadSource,
	getAllLeadSource,
	getAllLeadSourceList,
	getLeadSource,
	updateLeadSource,
	deleteLeadSource,
	addQuotationStatus,
	getAllQuotationStatus,
	getAllQuotationStatusList,
	getQuotationStatus,
	updateQuotationStatus,
	deleteQuotationStatus,
	addLeadProcess,
	getAllLeadProcess,
	getAllLeadProcessList,
	getLeadProcess,
	updateLeadProcess,
	deleteLeadProcess,
	addSaleRegion,
	getAllSaleRegions,
	getAllSaleRegionsList,
	getSaleRegion,
	updateSaleRegion,
	deleteSaleRegion,
	addLead,
	getAllLeads,
	getAllUnlinkedLeads,
	getAllLeadsList,
	getLead,
	updateLead,
	deleteLead,
	getLeadQuotations,
	//USERS
	addUser,
	getAllUsers,
	getAllUsersList,
	getUser,
	updateUser,
	deleteUser,
	addUserRole,
	getAllUserRoles,
	getAllUserRolesList,
	getUserRole,
	updateUserRole,
	deleteUserRole,
	//COMPANIES
	addCompany,
	getAllCompanies,
	getAllCompaniesList,
	getCompany,
	updateCompany,
	deleteCompany,
	addOutlet,
	getAllOutlets,
	getAllOutletsList,
	getOutlet,
	updateOutlet,
	deleteOutlet,
	//WAREHOUSES
	addWarehouse,
	getAllWarehouses,
	getWarehouse,
	updateWarehouse,
	deleteWarehouse,
	//CURRENCIES
	addCurrency,
	getAllCurrencies,
	getAllCurrenciesList,
	getCurrency,
	updateCurrency,
	deleteCurrency,
	//PAYMENTS
	addPayment,
	getAllPayments,
	getAllPaymentsList,
	getPayment,
	updatePayment,
	deletePayment,
	//RECEIPTS
	addReceipt,
	getAllReceipts,
	getCustomerReceipts,
	getReceipt,
	updateReceipt,
	deleteReceipt,
	getAllStatements,
	getAllStatementsBranch,
	//TRANSLATIONS
	generateTranslations,
	getTranslations,
	uploadTranslations,
	downloadTranslations,
	updateTranslations,
	// GLOBAL CONFIG
	getGlobalConfig,
	//SERVICE CALLS
	addServiceCallCategory,
	getAllServiceCallCategory,
	getServiceCallCategory,
	updateServiceCallCategory,
	deleteServiceCallCategory,
	getAllServiceCallCategoryList,
	getPaginatedSalesCalls,
	addServiceCall,
	getAllServiceCalls,
	getPaginatedCalls,
	getAllServiceCallsDashboard,
	getAllSalesCallsDashboard,
	getServiceCall,
	updateServiceCall,
	updateDeliveryDetails,
	cancelDelivery,
	updateComments,
	deleteComment,
	deleteSalesCall,
	addComments,
	deleteServiceCall,
	getCustomerServiceCalls,
	getServiceCallDelivery,
	addServiceCallType,
	getAllServiceCallType,
	getServiceCallType,
	updateServiceCallType,
	deleteServiceCallType,
	addServiceCallStatus,
	getAllServiceCallStatus,
	getAllServiceCallStatusList,
	getAllServiceCallTypeList,
	getAllServiceCallTypeCount,
	getServiceCallStatus,
	updateServiceCallStatus,
	deleteServiceCallStatus,

	//SALES CALLS
	addSalesCallCategory,
	getAllSalesCallCategory,
	getSalesCallCategory,
	updateSalesCallCategory,
	deleteSalesCallCategory,
	getAllSalesCallCategoryList,

	addSalesCall,
	getAllSalesCalls,
	getAllSalesCallsFilter,
	getSalesCall,
	updateSalesCall,
	updateSalesComments,
	deleteSalesComment,
	addSalesComments,
	addSalesCallType,
	getAllSalesCallType,
	getSalesCallType,
	updateSalesCallType,
	deleteSalesCallType,
	addSalesCallStatus,
	getAllSalesCallStatus,
	getAllSalesCallStatusList,
	getAllSalesCallTypeList,
	getAllSalesCallTypeCount,
	getSalesCallStatus,
	updateSalesCallStatus,
	deleteSalesCallStatus,

	// ZONES
	addZone,
	getAllZones,
	getZone,
	updateZone,
	deleteZone,
	//Merchant schema
	getAllCustomerSchema,
	getCustomerSchema,
	editCustomerSchema,
	addCustomerSchemaProducts,
	editCustomerSchemaProducts,
	deleteCustomerSchemaProducts,
	// upload image
	uploadCustLogo,
	//quotations
	getAllQuotationLostReason,
	getAllQuotationLostReasonList,
	getQuotationLostReason,
	addQuotationLostReason,
	updateQuotationLostReason,
	deleteQuotationLostReason,
	addQuotation,
	editQuotation,
	getAllQuotations,
	getAllQuotationsFilter,
	deleteQuotation,
	getQuotation,
	getQuotationUsers,
	getParameters,
	getParametersList,
	editParameters,
	//image
	uploadImage,
	uploadProductImage,
	//Pages
	editPage,
	editPages,
	editModulePage,
	getAllPages,
	getPageModules,
	addPage,
	deletePage,
	getRoutes,
	editRoutes,
	// subaccess
	getSubAccess,
	editSubAccess,
	getComments,
	getSalesComments,
	addAutomation,
	deleteAutomation,
	getAllAutomations,
	getAutomation,
	updateAutomation,
	//INVOICES
	addInvoice,
	editInvoice,
	deleteInvoice,
	getInvoice,
	getInvoiceUsers,
	getAllInvoices,
	getAllInvoicesFilter,
	getInvoiceParameters,
	getInvoiceParametersList,
	editInvoiceParameters,
	addOrganisation,
	addOrganisationLicense,
	editOrganisationLicense,
	getAllOrganisations,
	getOrganisations,
	getOrganisation,
	editRestaurant,
	addRestaurant,
	editRestBranch,
	addRestBranch,
	getRestaurantsBranches,
	getOrgRestaurantsBranches,
	getOrgCustomers,
	//requests
	getRequests,
	getRequestsCount,
	updateRequest,
	getEmailServer,
	getEmailDestinations,
	addEmailDestinations,
	addEmailServer,
	addLanguage,
	getAllLanguages,
	updateLanguage,
	deleteLanguage,
	getAllLanguagesList,
	getLanguage,
	//Activities
	getActivities
};