import {
    ADD_LANGUAGE,
    ADD_LANGUAGE_SUCCESS,
    GET_ALL_LANGUAGES_LIST,
    GET_ALL_LANGUAGES_LIST_SUCCESS,
    GET_ALL_LANGUAGES_SUCCESS,
    GET_ALL_LANGUAGES,
    UPDATE_LANGUAGE_SUCCESS,
    UPDATE_LANGUAGE,
    DELETE_LANGUAGE_SUCCESS,
    DELETE_LANGUAGE,
    GET_LANGUAGE,
    GET_LANGUAGE_SUCCESS,
    UPLOAD_LOGO
} from "../actions/Languages.action";

const initialState = {
    addLanguageLoading: false,
    getLanguagesLoading: false,
    getLanguageLoading: false,
    getLanguagesListLoading: false,
    updateLanguageLoading: false,
    deleteLanguageLoading: false,

    languages: [],
    languagesList: [],
    addedLanguage: "",
    currentLanguage: "",
    logoUrl:""
};

const RESET_LOADING = "RESET_LOADING"

function branch(state = initialState, action) {
    switch (action.type) {
        case UPLOAD_LOGO:
                    return Object.assign({}, state, {
                        logoUrl: action.payload.logoUrl,
        });
        case RESET_LOADING:
            return Object.assign({}, state, {
                addLanguageLoading: false,
                getLanguagesLoading: false,
                getLanguageLoading: false,
                getLanguagesListLoading: false,
                updateLanguageLoading: false,
                deleteLanguageLoading: false,
            })
        case ADD_LANGUAGE_SUCCESS:
            return Object.assign({}, state, {
                languages: action.payload.languages,
                addedLanguage: action.payload.addedLanguage
            });

        case ADD_LANGUAGE:
            return Object.assign({}, state, {
                addLanguageLoading: action.payload.addLanguageLoading,
            });

        case GET_ALL_LANGUAGES_SUCCESS:
            return Object.assign({}, state, {
                languages: action.payload.languages,
                currentLanguage: "",
            });

        case GET_ALL_LANGUAGES:
            return Object.assign({}, state, {
                getLanguagesLoading: action.payload.getLanguagesLoading
            });

        case GET_LANGUAGE_SUCCESS:
            return Object.assign({}, state, {
                currentLanguage: action.payload.currentLanguage,
                logoUrl: action.payload.currentLanguage?.logo
            });

        case GET_LANGUAGE:
            return Object.assign({}, state, {
                getLanguageLoading: action.payload.getLanguageLoading
            });

        case GET_ALL_LANGUAGES_LIST:
            return Object.assign({}, state, {
                getLanguagesListLoading: action.payload.getLanguagesListLoading
            });

        case GET_ALL_LANGUAGES_LIST_SUCCESS:
            return Object.assign({}, state, {
                languagesList: action.payload.languagesList
            });

        case UPDATE_LANGUAGE_SUCCESS:
            return Object.assign({}, state, {
                languages: action.payload.languages,
            });

        case UPDATE_LANGUAGE:
            return Object.assign({}, state, {
                updateLanguageLoading: action.payload.updateLanguageLoading
            });

        case DELETE_LANGUAGE_SUCCESS:
            return Object.assign({}, state, {
                languages: action.payload.languages,
            });

        case DELETE_LANGUAGE:
            return Object.assign({}, state, {
                deleteLanguageLoading: action.payload.deleteLanguageLoading,
            });

        default:
            return state;
    }
}

export default branch;
