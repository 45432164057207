import {
  addServiceCallCategory,
  updateServiceCallCategory,
  deleteServiceCallCategory,
  getAllServiceCallCategory,
  getServiceCallCategory,
  getAllServiceCallCategoryList,
  updateComments,
  addComments,
  cancelDelivery,
  addServiceCall,
  getPaginatedCalls,
  getServiceCall,
  getServiceCallDelivery,
  updateServiceCall,
  updateDeliveryDetails,
  deleteServiceCall,
  addServiceCallType,
  getAllServiceCallType,
  getServiceCallType,
  updateServiceCallType,
  deleteServiceCallType,
  addServiceCallStatus,
  getAllServiceCallStatus,
  getServiceCallStatus,
  updateServiceCallStatus,
  deleteServiceCallStatus,
  getAllServiceCallStatusList,
  getAllServiceCallTypeList,
  getAllServiceCallTypeCount,
  getAllSalesCallsDashboard,
  getAllServiceCallsDashboard,
  getComments,
  deleteComment,
  getCustomerServiceCalls,
} from "../../config/config";

import { toast } from "react-toastify";

export const API_REQUEST = "API_REQUEST";

export const GET_SERVICE_COMMENTS = "GET_SERVICE_COMMENTS";
export const GET_SERVICE_COMMENTS_SUCCESS = "GET_SERVICE_COMMENTS_SUCCESS";

export const DELETE_SERVICE_COMMENT = "DELETE_SERVICE_COMMENT";
export const DELETE_SERVICE_COMMENT_SUCCESS = "DELET_SERVICEE_COMMENT_SUCCESS";

export const GET_SERVICE_CALL_DELIVERY = "GET_SERVICE_CALL_DELIVERY";
export const GET_SERVICE_CALL_DELIVERY_SUCCESS =
  "GET_SERVICE_CALL_DELIVERY_SUCCESS";

export const ADD_SERVICE_CALL_SUCCESS = "ADD_SERVICE_CALL_SUCCESS";
export const GET_ALL_SERVICE_CALLS_SUCCESS = "GET_ALL_SERVICE_CALLS_SUCCESS";
export const GET_ALL_SERVICE_CALLS_FILTER_SUCCESS =
  "GET_ALL_SERVICE_CALLS_FILTER_SUCCESS";
export const GET_ALL_CALLS_FILTER_SUCCESS = "GET_ALL_CALLS_FILTER_SUCCESS";
export const GET_SERVICE_CALL_SUCCESS = "GET_SERVICE_CALL_SUCCESS";
export const UPDATE_SERVICE_CALL_SUCCESS = "UPDATE_SERVICE_CALL_SUCCESS";
export const DELETE_SERVICE_CALL_SUCCESS = "DELETE_SERVICE_CALL_SUCCESS";

export const ADD_SERVICE_CALL = "ADD_SERVICE_CALL";
export const GET_ALL_SERVICE_CALLS = "GET_ALL_SERVICE_CALLS";
export const GET_ALL_SERVICE_CALLS_FILTER = "GET_ALL_SERVICE_CALLS_FILTER";
export const GET_ALL_CALLS_FILTER = "GET_ALL_CALLS_FILTER";
export const GET_SERVICE_CALL = "GET_SERVICE_CALL";
export const UPDATE_SERVICE_CALL = "UPDATE_SERVICE_CALL";
export const DELETE_SERVICE_CALL = "DELETE_SERVICE_CALL";

export const CANCEL_SERVICE_CALL_DELIVERY_SUCCESS =
  "CANCEL_SERVICE_CALL_DELIVERY_SUCCESS";
export const CANCEL_SERVICE_CALL_DELIVERY = "CANCEL_SERVICE_CALL_DELIVERY";

export const GET_ALL_SERVICE_CALL_TYPE_COUNT =
  "GET_ALL_SERVICE_CALL_TYPE_COUNT";
export const ADD_SERVICE_CALL_TYPE_SUCCESS = "ADD_SERVICE_CALL_TYPE_SUCCESS";
export const GET_ALL_SERVICE_CALL_TYPE_SUCCESS =
  "GET_ALL_SERVICE_CALL_TYPE_SUCCESS";
export const GET_SERVICE_CALL_TYPE_SUCCESS = "GET_SERVICE_CALL_TYPE_SUCCESS";
export const UPDATE_SERVICE_CALL_TYPE_SUCCESS =
  "UPDATE_SERVICE_CALL_TYPE_SUCCESS";
export const DELETE_SERVICE_CALL_TYPE_SUCCESS =
  "DELETE_SERVICE_CALL_TYPE_SUCCESS";

export const ADD_SERVICE_CALL_TYPE = "ADD_SERVICE_CALL_TYPE";
export const GET_ALL_SERVICE_CALL_TYPE = "GET_ALL_SERVICE_CALL_TYPE";
export const GET_SERVICE_CALL_TYPE = "GET_SERVICE_CALL_TYPE";
export const UPDATE_SERVICE_CALL_TYPE = "UPDATE_SERVICE_CALL_TYPE";
export const DELETE_SERVICE_CALL_TYPE = "DELETE_SERVICE_CALL_TYPE";

export const ADD_SERVICE_CALL_STATUS_SUCCESS =
  "ADD_SERVICE_CALL_STATUS_SUCCESS";
export const GET_ALL_SERVICE_CALL_STATUS_SUCCESS =
  "GET_ALL_SERVICE_CALL_STATUS_SUCCESS";
export const GET_SERVICE_CALL_STATUS_SUCCESS =
  "GET_SERVICE_CALL_STATUS_SUCCESS";
export const UPDATE_SERVICE_CALL_STATUS_SUCCESS =
  "UPDATE_SERVICE_CALL_STATUS_SUCCESS";
export const DELETE_SERVICE_CALL_STATUS_SUCCESS =
  "DELETE_SERVICE_CALL_STATUS_SUCCESS";

export const ADD_SERVICE_CALL_STATUS = "ADD_SERVICE_CALL_STATUS";
export const GET_ALL_SERVICE_CALL_STATUS = "GET_ALL_SERVICE_CALL_STATUS";
export const GET_SERVICE_CALL_STATUS = "GET_SERVICE_CALL_STATUS";
export const UPDATE_SERVICE_CALL_STATUS = "UPDATE_SERVICE_CALL_STATUS";
export const DELETE_SERVICE_CALL_STATUS = "DELETE_SERVICE_CALL_STATUS";

export const UPDATE_SERVICE_CALL_DELIVERY = "UPDATE_SERVICE_CALL_DELIVERY";
export const UPDATE_SERVICE_CALL_DELIVERY_SUCCESS =
  "UPDATE_SERVICE_CALL_DELIVERY_SUCCESS";

export const GET_ALL_SERVICE_CALL_TYPE_LIST = "GET_ALL_SERVICE_CALL_TYPE_LIST";
export const GET_ALL_SERVICE_CALL_STATUS_LIST =
  "GET_ALL_SERVICE_CALL_STATUS_LIST";

export const ADD_SERVICE_CALL_CATEGORY_SUCCESS =
  "ADD_SERVICE_CALL_CATEGORY_SUCCESS";
export const GET_ALL_SERVICE_CALL_CATEGORY_SUCCESS =
  "GET_ALL_SERVICE_CALL_CATEGORY_SUCCESS";
export const GET_SERVICE_CALL_CATEGORY_SUCCESS =
  "GET_SERVICE_CALL_CATEGORY_SUCCESS";
export const UPDATE_SERVICE_CALL_CATEGORY_SUCCESS =
  "UPDATE_SERVICE_CALL_CATEGORY_SUCCESS";
export const DELETE_SERVICE_CALL_CATEGORY_SUCCESS =
  "DELETE_SERVICE_CALL_CATEGORY_SUCCESS";
export const GET_ALL_SERVICE_CALL_CATEGORY_LIST =
  "GET_ALL_SERVICE_CALL_CATEGORY_LIST";

export const ADD_SERVICE_CALL_CATEGORY = "ADD_SERVICE_CALL_CATEGORY";
export const GET_ALL_SERVICE_CALL_CATEGORY = "GET_ALL_SERVICE_CALL_CATEGORY";
export const GET_SERVICE_CALL_CATEGORY = "GET_SERVICE_CALL_CATEGORY";
export const UPDATE_SERVICE_CALL_CATEGORY = "UPDATE_SERVICE_CALL_CATEGORY";
export const DELETE_SERVICE_CALL_CATEGORY = "DELETE_SERVICE_CALL_CATEGORY";

export const SAVE_SERVICE_CHECKLIST = "SAVE_SERVICE_CHECKLIST";
export const SAVE_SERVICE_TEMP_TASKS = "SAVE_TEMP_TASKS";

export const SET_SERVICE_EDIT_ID = "SET_SERVICE_EDIT_ID";
export const SET_SERVICE_EDIT_VISIBLE = "SET_SERVICE_EDIT_VISIBLE";

export const SET_SERVICE_CHECK_VISIBLE = "SET_SERVICE_CHECK_VISIBLE";
export const SET_SERVICE_CHECK_DATA = "SET_SERVICE_CHECK_DATA";

export const UPDATE_SERVICE_COMMENTS = "UPDATE_SERVICE_COMMENTS";
export const UPDATE_SERVICE_COMMENTS_SUCCESS =
  "UPDATE_SERVICE_COMMENTS_SUCCESS";

export const ADD_SERVICE_COMMENTS = "ADD_SERVICE_COMMENTS";
export const ADD_SERVICE_COMMENTS_SUCCESS = "ADD_SERVICE_COMMENTS_SUCCESS";

export const GET_CUSTOMER_SERVICE_CALLS_SUCCESS =
  "GET_CUSTOMER_SERVICE_CALLS_SUCCESS";
export const GET_CUSTOMER_SERVICE_CALLS = "GET_CUSTOMER_SERVICE_CALLS";

const getServiceCalls = (
  page,
  limit,
  status,
  filters,
  loading,
  setPage,
  setFailedPage,
  view,
  dispatch
) => {
  if (loading)
    dispatch({
      type: GET_ALL_SERVICE_CALLS_FILTER,
      payload: {
        getServiceCallsFilterLoading: true,
      },
    });
  else
    dispatch({
      type: GET_ALL_SERVICE_CALLS,
      payload: {
        getServiceCallsLoading: true,
      },
    });
  getPaginatedCalls(page, limit, status, filters)
    .then((res) => {
      if (loading)
        dispatch({
          type: GET_ALL_SERVICE_CALLS_FILTER,
          payload: {
            getServiceCallsFilterLoading: false,
          },
        });
      else
        dispatch({
          type: GET_ALL_SERVICE_CALLS,
          payload: {
            getServiceCallsLoading: false,
          },
        });
      if (res?.status === 200) {
        if (typeof setPage === "function" && view) {
          setPage(page + 1);
        }
        if (typeof setPage === "function" && !view) {
          setPage();
        }
        dispatch({
          type: "SET_PAGE_DETAILS",
          payload: {
            page: "serviceCalls",
            currentPage: res?.data?.currentPage,
            totalPages: res?.data?.totalPages,
            totalRecords: res?.data?.totalRecords,
          },
        });
        dispatch({
          type: GET_ALL_SERVICE_CALLS_FILTER_SUCCESS,
          payload: {
            serviceCalls: res?.data?.serviceCalls,
            page: res?.data?.currentPage,
            totalPages: res?.data?.totalPages,
            totalRecords: res?.data?.totalRecords,
            view,
            groupedServiceCalls: res?.data?.groupedByDate,
            groupedTypes: res?.data?.groupedtypes,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    })
    .catch(() => {
      if (typeof setFailedPage === "function") {
        setFailedPage(page);
      }
    });
};

const actions = {
  //ADD SERVICE CALL
  addServiceCall: (data, isRedirect, history) => (dispatch) => {
    dispatch({
      type: ADD_SERVICE_CALL,
      payload: {
        addServiceCallLoading: true,
      },
    });

    addServiceCall(data).then((res) => {
      dispatch({
        type: ADD_SERVICE_CALL,
        payload: {
          addServiceCallLoading: false,
        },
      });

      if (res?.status === 200) {
        dispatch({
          type: ADD_SERVICE_CALL_SUCCESS,
          payload: {
            serviceCalls: res?.data?.serviceCalls,
            groupedServiceCalls: res?.data?.groupedResponse,
            addedServiceCall: res?.data?.addedServiceCall,
          },
        });
        toast.success(res?.data?.message || "Success", { theme: "colored" });
        if (isRedirect) history.push("/service-tasks");
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //FETCH ALL SERVICE CALLS
  getPaginatedCalls:
    (
      page,
      limit,
      status,
      filters,
      loading,
      setPage,
      setFailedPage = null,
      view
    ) =>
    (dispatch) => {
      getServiceCalls(
        page,
        limit,
        status,
        filters,
        loading,
        setPage,
        setFailedPage,
        view,
        dispatch
      );
    },

  //FETCH ALL SERVICE CALLS
  getAllCallsDashboard: (filter) => (dispatch) => {
    dispatch({
      type: GET_ALL_CALLS_FILTER,
      payload: {
        getCallsFilterLoading: true,
      },
    });
    Promise.all([
      getAllServiceCallsDashboard(filter),
      getAllSalesCallsDashboard(filter),
    ])?.then(([res1, res2]) => {
      dispatch({
        type: GET_ALL_CALLS_FILTER,
        payload: {
          getCallsFilterLoading: false,
        },
      });

      if (res1?.status === 200 && res2?.status === 200) {
        dispatch({
          type: GET_ALL_CALLS_FILTER_SUCCESS,
          payload: {
            serviceCalls: res1?.data?.serviceCalls,
            groupedServiceCalls: res1?.data?.groupedResponse,
            salesCalls: res2?.data?.salesCalls,
            groupedSalesCalls: res2?.data?.groupedResponse,
          },
        });
      } else if (res1?.status === 401 || res2?.status === 401) {
        window.location = "/session-ended";
      } else if (res1?.status === 403 || res2?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(
          `${res1?.data?.message} ${res2?.data?.message}` || "error",
          { theme: "colored" }
        );
      }
    });
  },

  //GET CUSTOMER SERVICE CALLS
  getCustomerServiceCalls: (id) => (dispatch) => {
    dispatch({
      type: GET_CUSTOMER_SERVICE_CALLS,
      payload: {
        getCustomerServiceCallsLoading: true,
      },
    });
    getCustomerServiceCalls(id).then((res) => {
      dispatch({
        type: GET_CUSTOMER_SERVICE_CALLS,
        payload: {
          getCustomerServiceCallsLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_CUSTOMER_SERVICE_CALLS_SUCCESS,
          payload: {
            customerServiceCalls: res?.data?.serviceCalls,
            groupedServiceCalls: res?.data?.groupedResponse,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //FETCH ALL SERVICE CALLS
  getComments: (id) => (dispatch) => {
    dispatch({
      type: GET_SERVICE_COMMENTS,
      payload: {
        getCommentsLoading: true,
      },
    });
    getComments(id).then((res) => {
      dispatch({
        type: GET_SERVICE_COMMENTS,
        payload: {
          getCommentsLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_SERVICE_COMMENTS_SUCCESS,
          payload: {
            comments: res?.data?.serviceCallActivities,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //GET SINGLE SERVICE CALL
  getServiceCall: (id) => (dispatch) => {
    dispatch({
      type: GET_SERVICE_CALL,
      payload: {
        getServiceCallLoading: true,
      },
    });
    getServiceCall(id).then((res) => {
      dispatch({
        type: GET_SERVICE_CALL,
        payload: {
          getServiceCallLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_SERVICE_CALL_SUCCESS,
          payload: {
            currentServiceCall: res?.data?.serviceCall,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //GET  SERVICE CALL DELIVERY
  getServiceCallDelivery: (id) => (dispatch) => {
    dispatch({
      type: GET_SERVICE_CALL_DELIVERY,
      payload: {
        getServiceCallDeliveryLoading: true,
      },
    });
    getServiceCallDelivery(id).then((res) => {
      dispatch({
        type: GET_SERVICE_CALL_DELIVERY,
        payload: {
          getServiceCallDeliveryLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_SERVICE_CALL_DELIVERY_SUCCESS,
          payload: {
            serviceCallDelivery: res?.data?.notes,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },
  //UPDATE SERVICE CALL
  updateServiceCall: (data, id, isRedirect, history) => (dispatch) => {
    dispatch({
      type: UPDATE_SERVICE_CALL,
      payload: {
        updateServiceCallLoading: true,
      },
    });

    updateServiceCall(data, id).then((res) => {
      dispatch({
        type: UPDATE_SERVICE_CALL,
        payload: {
          updateServiceCallLoading: false,
        },
      });

      if (res?.status === 200) {
        dispatch({
          type: UPDATE_SERVICE_CALL_SUCCESS,
          payload: {
            serviceCalls: res?.data?.serviceCalls,
            groupedServiceCalls: res?.data?.groupedResponse,
            addedServiceCall: res?.data?.addedServiceCall,
          },
        });
        toast.success(res?.data?.message || "Success", { theme: "colored" });
        if (isRedirect) history?.push("/service-tasks");
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //UPDATE DELIVERY
  updateServiceCallDelivery: (data, id) => (dispatch) => {
    dispatch({
      type: UPDATE_SERVICE_CALL_DELIVERY,
      payload: {
        updateServiceCallDeliveryLoading: true,
      },
    });

    updateDeliveryDetails(data, id).then((res) => {
      dispatch({
        type: UPDATE_SERVICE_CALL_DELIVERY,
        payload: {
          updateServiceCallDeliveryLoading: false,
        },
      });

      if (res?.status === 200) {
        dispatch({
          type: UPDATE_SERVICE_CALL_DELIVERY_SUCCESS,
          payload: {
            serviceCallDelivery: res?.data?.notes,
          },
        });
        toast.success(res?.data?.message || "Success", { theme: "colored" });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //CANCEL DELIVERY
  cancelDelivery: (data, id) => (dispatch) => {
    dispatch({
      type: CANCEL_SERVICE_CALL_DELIVERY,
      payload: {
        cancelDeliveryLoading: true,
      },
    });

    cancelDelivery(data, id).then((res) => {
      dispatch({
        type: CANCEL_SERVICE_CALL_DELIVERY,
        payload: {
          cancelDeliveryLoading: false,
        },
      });

      if (res?.status === 200) {
        dispatch({
          type: CANCEL_SERVICE_CALL_DELIVERY_SUCCESS,
          payload: {
            serviceCallDelivery: res?.data?.notes,
          },
        });
        toast.success(res?.data?.message || "Success", { theme: "colored" });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },
  //FETCH ALL SERVICE CALLS
  updateComments: (data, id) => (dispatch) => {
    dispatch({
      type: UPDATE_SERVICE_COMMENTS,
      payload: {
        updateCommentsLoading: true,
      },
    });
    updateComments(data, id).then((res) => {
      dispatch({
        type: UPDATE_SERVICE_COMMENTS,
        payload: {
          updateCommentsLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: UPDATE_SERVICE_COMMENTS_SUCCESS,
          payload: {
            comments: res?.data?.serviceCallActivities,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //FETCH ALL SERVICE CALLS
  deleteComment: (id) => (dispatch) => {
    dispatch({
      type: DELETE_SERVICE_COMMENT,
      payload: {
        deleteCommentLoading: true,
      },
    });
    deleteComment(id).then((res) => {
      dispatch({
        type: DELETE_SERVICE_COMMENT,
        payload: {
          deleteCommentLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: DELETE_SERVICE_COMMENT_SUCCESS,
          payload: {
            comments: res?.data?.serviceCallActivities,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //FETCH ALL SERVICE CALLS
  addComments: (data) => (dispatch) => {
    dispatch({
      type: ADD_SERVICE_COMMENTS,
      payload: {
        addCommentsLoading: true,
      },
    });
    addComments(data).then((res) => {
      dispatch({
        type: ADD_SERVICE_COMMENTS,
        payload: {
          addCommentsLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: ADD_SERVICE_COMMENTS_SUCCESS,
          payload: {
            comments: res?.data?.serviceCallActivities,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },
  //DELETE SERVICE CALL
  deleteServiceCall: (details, deleteTask) => (dispatch) => {
    dispatch({
      type: DELETE_SERVICE_CALL,
      payload: {
        deleteServiceCallLoading: true,
      },
    });
    deleteServiceCall(details.id, "pending").then((res) => {
      dispatch({
        type: DELETE_SERVICE_CALL,
        payload: {
          deleteServiceCallLoading: false,
        },
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message || "Success", { theme: "colored" });
        deleteTask(details);
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //ADD SERVICE CALL TYPE
  addServiceCallType: (data) => (dispatch) => {
    dispatch({
      type: ADD_SERVICE_CALL_TYPE,
      payload: {
        addServiceCallTypeLoading: true,
      },
    });

    addServiceCallType(data).then((res) => {
      dispatch({
        type: ADD_SERVICE_CALL_TYPE,
        payload: {
          addServiceCallTypeLoading: false,
        },
      });

      if (res?.status === 200) {
        toast.success(res?.data?.message || "Success", { theme: "colored" });
        dispatch({
          type: ADD_SERVICE_CALL_TYPE_SUCCESS,
          payload: {
            serviceCallTypes: res?.data?.serviceCallTypes,
            addedType: res?.data?.addedServiceCallType,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //FETCH ALL SERVICE CALL TYPE
  getAllServiceCallType: () => (dispatch) => {
    dispatch({
      type: GET_ALL_SERVICE_CALL_TYPE,
      payload: {
        getServiceCallTypesLoading: true,
      },
    });
    getAllServiceCallType().then((res) => {
      dispatch({
        type: GET_ALL_SERVICE_CALL_TYPE,
        payload: {
          getServiceCallTypesLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_ALL_SERVICE_CALL_TYPE_SUCCESS,
          payload: {
            serviceCallTypes: res?.data?.serviceCallTypes,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //FETCH ALL SERVICE CALL TYPE
  getAllServiceCallTypeList: () => (dispatch) => {
    getAllServiceCallTypeList().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_ALL_SERVICE_CALL_TYPE_LIST,
          payload: {
            serviceCallTypesList: res?.data?.serviceCallTypes,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        toast.error("service call type");
        // window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },
  //FETCH ALL SERVICE CALL TYPE COUNT
  getAllServiceCallTypeCount: (from, to, status) => (dispatch) => {
    getAllServiceCallTypeCount(from, to, status).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_ALL_SERVICE_CALL_TYPE_COUNT,
          payload: {
            serviceCallTypesCount: res?.data?.serviceCallTypes,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        toast.error("service call type");
        // window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //GET SINGLE SERVICE CALL TYPE
  getServiceCallType: (id) => (dispatch) => {
    dispatch({
      type: GET_SERVICE_CALL_TYPE,
      payload: {
        getServiceCallTypeLoading: true,
      },
    });
    getServiceCallType(id).then((res) => {
      dispatch({
        type: GET_SERVICE_CALL_TYPE,
        payload: {
          getServiceCallTypeLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_SERVICE_CALL_TYPE_SUCCESS,
          payload: {
            currentServiceCallType: res?.data?.serviceCallType,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //UPDATE SERVICE CALL TYPE
  updateServiceCallType: (data, id, history) => (dispatch) => {
    dispatch({
      type: UPDATE_SERVICE_CALL_TYPE,
      payload: {
        updateServiceCallTypeLoading: true,
      },
    });

    updateServiceCallType(data, id).then((res) => {
      dispatch({
        type: UPDATE_SERVICE_CALL_TYPE,
        payload: {
          updateServiceCallTypeLoading: false,
        },
      });

      if (res?.status === 200) {
        dispatch({
          type: UPDATE_SERVICE_CALL_TYPE_SUCCESS,
          payload: {
            serviceCallTypes: res?.data?.serviceCallTypes,
          },
        });
        history.push("/service-task-type");
        toast.success(res?.data?.message || "Success", { theme: "colored" });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //DELETE SERVICE CALL TYPE
  deleteServiceCallType: (id) => (dispatch) => {
    dispatch({
      type: DELETE_SERVICE_CALL_TYPE,
      payload: {
        deleteServiceCallTypeLoading: true,
      },
    });
    deleteServiceCallType(id).then((res) => {
      dispatch({
        type: DELETE_SERVICE_CALL_TYPE,
        payload: {
          deleteServiceCallTypeLoading: false,
        },
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message || "Success", { theme: "colored" });
        dispatch({
          type: DELETE_SERVICE_CALL_TYPE_SUCCESS,
          payload: {
            serviceCallTypes: res?.data?.serviceCallTypes,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //ADD SERVICE CALL STATUS
  addServiceCallStatus: (data) => (dispatch) => {
    dispatch({
      type: ADD_SERVICE_CALL_STATUS,
      payload: {
        addServiceCallStatusLoading: true,
      },
    });

    addServiceCallStatus(data).then((res) => {
      dispatch({
        type: ADD_SERVICE_CALL_STATUS,
        payload: {
          addServiceCallStatusLoading: false,
        },
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message || "Success", { theme: "colored" });
        dispatch({
          type: ADD_SERVICE_CALL_STATUS_SUCCESS,
          payload: {
            serviceCallStatus: res?.data?.serviceCallStatuses,
            addedStatus: res?.data?.addedServiceCallStatus,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //FETCH ALL SERVICE CALL STATUS
  getAllServiceCallStatus: () => (dispatch) => {
    dispatch({
      type: GET_ALL_SERVICE_CALL_STATUS,
      payload: {
        getServiceCallStatusesLoading: true,
      },
    });
    getAllServiceCallStatus().then((res) => {
      dispatch({
        type: GET_ALL_SERVICE_CALL_STATUS,
        payload: {
          getServiceCallStatusesLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_ALL_SERVICE_CALL_STATUS_SUCCESS,
          payload: {
            serviceCallStatus: res?.data?.serviceCallStatuses,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //FETCH ALL SERVICE CALL STATUS
  getAllServiceCallStatusList: () => (dispatch) => {
    getAllServiceCallStatusList().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_ALL_SERVICE_CALL_STATUS_LIST,
          payload: {
            serviceCallStatusList: res?.data?.serviceCallStatuses,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        toast.error("service call status");
        // window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //GET SINGLE SERVICE CALL STATUS
  getServiceCallStatus: (id) => (dispatch) => {
    dispatch({
      type: GET_SERVICE_CALL_STATUS,
      payload: {
        getServiceCallStatusLoading: true,
      },
    });
    getServiceCallStatus(id).then((res) => {
      dispatch({
        type: GET_SERVICE_CALL_STATUS,
        payload: {
          getServiceCallStatusLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_SERVICE_CALL_STATUS_SUCCESS,
          payload: {
            currentServiceCallStatus: res?.data?.serviceCallStatus,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //UPDATE SERVICE CALL STATUS
  updateServiceCallStatus: (data, id, history) => (dispatch) => {
    dispatch({
      type: UPDATE_SERVICE_CALL_STATUS,
      payload: {
        updateServiceCallStatusLoading: true,
      },
    });

    updateServiceCallStatus(data, id).then((res) => {
      dispatch({
        type: UPDATE_SERVICE_CALL_STATUS,
        payload: {
          updateServiceCallStatusLoading: false,
        },
      });

      if (res?.status === 200) {
        dispatch({
          type: UPDATE_SERVICE_CALL_STATUS_SUCCESS,
          payload: {
            serviceCallStatus: res?.data?.serviceCallStatuses,
          },
        });
        history.push("/service-task-status");
        toast.success(res?.data?.message || "Success", { theme: "colored" });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //DELETE SERVICE CALL STATUS
  deleteServiceCallStatus: (id) => (dispatch) => {
    dispatch({
      type: DELETE_SERVICE_CALL_STATUS,
      payload: {
        deleteServiceCallStatusLoading: true,
      },
    });
    deleteServiceCallStatus(id).then((res) => {
      dispatch({
        type: DELETE_SERVICE_CALL_STATUS,
        payload: {
          deleteServiceCallStatusLoading: false,
        },
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message || "Success", { theme: "colored" });
        dispatch({
          type: DELETE_SERVICE_CALL_STATUS_SUCCESS,
          payload: {
            serviceCallStatus: res?.data?.serviceCallStatuses,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //ADD SERVICE CALL CATEGORY
  addServiceCallCategory: (data) => (dispatch) => {
    dispatch({
      type: ADD_SERVICE_CALL_CATEGORY,
      payload: {
        addServiceCallCategoryLoading: true,
      },
    });

    addServiceCallCategory(data).then((res) => {
      dispatch({
        type: ADD_SERVICE_CALL_CATEGORY,
        payload: {
          addServiceCallCategoryLoading: false,
        },
      });

      if (res?.status === 200) {
        toast.success(res?.data?.message || "Success", { theme: "colored" });
        dispatch({
          type: ADD_SERVICE_CALL_CATEGORY_SUCCESS,
          payload: {
            serviceCallCategories: res?.data?.serviceCallDepartments,
            addedCategory: res?.data?.addedServiceCallDepartment,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //FETCH ALL SERVICE CALL CATEGORY
  getAllServiceCallCategory: () => (dispatch) => {
    dispatch({
      type: GET_ALL_SERVICE_CALL_CATEGORY,
      payload: {
        getServiceCallCategoriesLoading: true,
      },
    });
    getAllServiceCallCategory().then((res) => {
      dispatch({
        type: GET_ALL_SERVICE_CALL_CATEGORY,
        payload: {
          getServiceCallCategoriesLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_ALL_SERVICE_CALL_CATEGORY_SUCCESS,
          payload: {
            serviceCallCategories: res?.data?.serviceCallDepartments,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //FETCH ALL SERVICE CALL CATEGORY LIST
  getAllServiceCallCategoryList: () => (dispatch) => {
    getAllServiceCallCategoryList().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_ALL_SERVICE_CALL_CATEGORY_LIST,
          payload: {
            serviceCallCategoriesList: res?.data?.serviceCallDepartments,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        toast.error("service call type");
        // window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //DELETE SERVICE CALL CATEGORY
  deleteServiceCallCategory: (id) => (dispatch) => {
    dispatch({
      type: DELETE_SERVICE_CALL_CATEGORY,
      payload: {
        deleteServiceCallCategoryLoading: true,
      },
    });
    deleteServiceCallCategory(id).then((res) => {
      dispatch({
        type: DELETE_SERVICE_CALL_CATEGORY,
        payload: {
          deleteServiceCallCategoryLoading: false,
        },
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message || "Success", { theme: "colored" });
        dispatch({
          type: DELETE_SERVICE_CALL_CATEGORY_SUCCESS,
          payload: {
            serviceCallCategories: res?.data?.serviceCallDepartments,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //UPDATE SERVICE CALL CATEGORY
  updateServiceCallCategory: (data, id, history) => (dispatch) => {
    dispatch({
      type: UPDATE_SERVICE_CALL_CATEGORY,
      payload: {
        updateServiceCallCategoryLoading: true,
      },
    });

    updateServiceCallCategory(data, id).then((res) => {
      dispatch({
        type: UPDATE_SERVICE_CALL_CATEGORY,
        payload: {
          updateServiceCallCategoryLoading: false,
        },
      });

      if (res?.status === 200) {
        dispatch({
          type: UPDATE_SERVICE_CALL_CATEGORY_SUCCESS,
          payload: {
            serviceCallCategories: res?.data?.serviceCallDepartments,
          },
        });
        history.push("/support-department");
        toast.success(res?.data?.message || "Success", { theme: "colored" });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },

  //GET SINGLE SERVICE CALL CATEGORY
  getServiceCallCategory: (id) => (dispatch) => {
    dispatch({
      type: GET_SERVICE_CALL_CATEGORY,
      payload: {
        getServiceCallCategoryLoading: true,
      },
    });
    getServiceCallCategory(id).then((res) => {
      dispatch({
        type: GET_SERVICE_CALL_CATEGORY,
        payload: {
          getServiceCallCategoryLoading: false,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_SERVICE_CALL_CATEGORY_SUCCESS,
          payload: {
            currentServiceCallCategory: res?.data?.serviceCallDepartment,
          },
        });
      } else if (res?.status === 401) {
        window.location = "/session-ended";
      } else if (res?.status === 403) {
        window.location = "/forbidden";
      } else {
        toast.error(res?.data?.message || "error", { theme: "colored" });
      }
    });
  },
  saveChecklist: (data) => (dispatch) => {
    dispatch({ type: SAVE_SERVICE_CHECKLIST, payload: { checklist: data } });
  },
  saveTempTasks: (data) => (dispatch) => {
    dispatch({ type: SAVE_SERVICE_TEMP_TASKS, payload: { tasks: data } });
  },
  setEditId: (data) => (dispatch) => {
    dispatch({ type: SET_SERVICE_EDIT_ID, payload: { editId: data } });
  },
  setEditVisible: (data) => (dispatch) => {
    dispatch({
      type: SET_SERVICE_EDIT_VISIBLE,
      payload: { editVisible: data },
    });
  },
  setCheckVisible: (data) => (dispatch) => {
    dispatch({
      type: SET_SERVICE_CHECK_VISIBLE,
      payload: { checkVisible: data },
    });
  },
  setCheckData: (data) => (dispatch) => {
    dispatch({ type: SET_SERVICE_CHECK_DATA, payload: { checkData: data } });
  },
};

export default actions;
